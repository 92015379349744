import React, { ReactElement, useEffect, useState } from "react";
import "./App.scss";
import {
  Button,
  Card,
  Checkbox,
  DotLoading,
  ErrorBlock,
  Form,
  IndexBar,
  Input,
  List,
  Modal,
  NoticeBar,
  Picker,
  Popup,
  Result,
  SearchBar,
  Skeleton,
  SpinLoading,
  Stepper,
  Steps,
  Switch,
  TextArea,
  Toast,
} from "antd-mobile";
import {
  AntOutline,
  CheckCircleFill,
  DownOutline,
  EditFill,
  EditSFill,
  HeartFill,
  InformationCircleFill,
  LocationFill,
  RightOutline,
  TeamFill,
} from "antd-mobile-icons";
import {
  useFetchAddress,
  useFetchCheckPackage,
  useFetchCityByLocation,
  useFetchCityList,
  useFetchCustomization,
  useFetchOrderInfo,
  useFetchPackage,
  useFetchSaveAddress,
  useFetchStores,
  useFetchSubmit,
} from "./service";
import copy from "copy-to-clipboard";
import Logo from "./img/logo.jpg";

const { Step } = Steps;

function App() {
  const [step, setStep] = useState(0);
  const [addr, setAddr] = useState<any>({});
  const [store, setStore] = useState<any>({});
  const [cityPopupVisible, setCityPopupVisible] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<any>({});
  const [secondaryPopupVisible, setSecondaryPopupVisible] = useState(false);
  const [hasOrder, setHasOrder] = useState(false);
  const [isPaying, setIsPaying] = useState(false);
  const [isOrderFistLoading, setIsOrderFistLoading] = useState(true);
  const [checkedInfo, setCheckedInfo] = useState<any>({});
  const [userAddrInfo, setUserAddrInfo] = useState<any>({});
  const [remark, setRemark] = useState<string>("");
  const [tablewareCode, setTablewareCode] = useState<string>("no");
  const [emptyContent, setEmptyContent] = useState(false);

  const [packageProductList, setPackageProductList] = useState<any[]>([]);

  const { data: addrList, fetchAddress, loading: isAddrLoading } = useFetchAddress();
  const { data: storeList, fetchStores } = useFetchStores();
  const { data: city, fetchCityByLocation, setData: setCity } = useFetchCityByLocation();
  const { data: cityList, fetchCityList } = useFetchCityList();
  const { data: packageData, fetchPackage } = useFetchPackage();

  const {
    data: customization,
    fetchCustomization,
    setData: setCustomization,
    loading: isCustomizationLoading,
  } = useFetchCustomization();
  const { data: orderInfo, fetchOrderInfo } = useFetchOrderInfo();
  const { fetchSaveAddress, loading: isSaveAddressLoading } = useFetchSaveAddress();
  const { fetchSubmit, loading: isSubmitLoading } = useFetchSubmit();
  const { data: checkPackage, fetchCheckPackage } = useFetchCheckPackage();

  const selectedProducts = () => {
    // 对象数组转为数组
    return selectedPackage?.reduce((prev, cur) => {
      return prev.concat(cur);
    }, []);
  };

  // 下单过: dF6hhUEE, 没下单过: xLVaxXdb
  const pickupCode = new URLSearchParams(window.location.search).get("pickupCode");

  const fetchOrder = () =>
    fetchOrderInfo(pickupCode).then((orderInfo) => {
      setHasOrder(!!orderInfo);
      if (!orderInfo) {
        fetchPackage(pickupCode).then((res: any) => {
          if (res) {
            setSelectedPackage(res?.[0]);
            const products = [...(res?.[0]?.originalProducts || []), ...(res?.[0]?.couponProducts || [])].filter(
              Boolean
            );
            setPackageProductList(products);
          }
          setEmptyContent(!res);
        });
        fetchCityList();
        getLocation();
      } else {
        // 支付中
        if (orderInfo?.orderStatusCode == 1) {
          setIsPaying(true);
        } else {
          setIsPaying(false);
        }
      }
    });

  useEffect(() => {
    if (pickupCode) {
      fetchOrder().finally(() => {
        setIsOrderFistLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    // 轮询订单状态
    const interval = setInterval(() => {
      orderInfo && fetchOrder();
    }, 20000);

    // return 注销定时器
    return () => {
      clearInterval(interval);
    };
  }, [orderInfo]);

  const getLocation = () => {
    // 检查浏览器是否支持Geolocation API
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // 获取用户的经纬度
          const { latitude, longitude } = position.coords;
          console.log("{ latitude, longitude } :>> ", { latitude, longitude });
          fetchCityByLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting geolocation:", error.message);
        }
      );
    } else {
      console.error("Geolocation not supported in this browser");
    }
  };

  const onAddrItemClick = async (item: any) => {
    // 请求门店
    const stores = await fetchStores(item);
    // if (stores?.length < 1) {
    //   return;
    // }
    // if (stores && stores?.length > 0) {
    //   // 第一个设置为默认
    //   setStore(stores[0]);
    // }

    setStep(1);
    setAddr(item);
  };

  const onAddrFormSubmit = async (values: any) => {
    // 保存地址
    const json = {
      pickupCode,
      detail: values.detailAddr,
      phone: values.phone,
      cityName: addr.cityName,
      longitude: addr.longitude,
      latitude: addr.latitude,
      streetAddress: addr.address,
      cityCode: addr.cityCode,
      address: addr.name,
      fullName: values.name,
      gender: 0,
    };
    const saveAddrRes = await fetchSaveAddress(json);
    if (!saveAddrRes) {
      return;
    }
    setUserAddrInfo(json);
    setStep(2);
    console.log("json :>> ", json);
  };

  const onCityListItemClick = (city) => {
    city.name && setCity(city.name);
    setCityPopupVisible(false);
  };

  // const onSelectPackage = async (item) => {
  //   const res = await fetchCheckPackage({ pickupCode, storeCode: store.code, packageId: item.id });
  //   if (res) {
  //     setSelectedPackage(item);
  //     const products = [...(item?.originalProducts || []), ...(item?.couponProducts || [])].filter(Boolean);
  //     setPackageProductList(products);
  //     setStep(3);
  //   } else {
  //     Toast.show({
  //       content: "该套餐不可选",
  //     });
  //   }
  // };

  const onFoodChecked = (productId, checked, food, round) => {
    const temp = [...packageProductList];
    const product = temp.find((item) => item.id === productId);
    const secondary = product.secondary.find((item) => item.round === round);

    const maxNum = secondary.maxQuantity || 1;
    // 累计勾选种类
    const currNum = secondary.details?.filter((item) => item.checked).length;

    // 累计数量
    const total = secondary.details
      .filter((item) => item.id !== food.id && item.checked)
      .reduce((acc, cur) => {
        return acc + cur.quantity;
      }, 0);

    // 不可超过最大可选数量
    if ((checked && total >= maxNum) || (maxNum > 1 && checked && currNum >= maxNum)) {
      Toast.show({
        content: `${secondary.className}最多只能选${secondary.maxQuantity}个`,
        position: "top",
      });
      return;
    }

    const details = secondary.details.map((detail) => {
      if (detail.id === food.id) {
        detail.checked = checked ? 1 : 0;
        if (!checked) {
          detail.quantity = 0;
        }
        if (maxNum === 1) {
          detail.quantity = 1;
        }
      } else {
        if (maxNum === 1) {
          // 单选, 自动去掉其他选项
          detail.checked = 0;
        }
        if (!checked) {
          detail.quantity = 0;
        }
      }

      return detail;
    });

    secondary.details = details;

    setPackageProductList(temp);
  };

  const validateQuantity = () => {
    const res = packageProductList.map((product) => {
      const res = product?.secondary?.map((secondary) => {
        if (secondary.maxQuantity === 1) {
          return true;
        }
        const maxNum = secondary?.maxQuantity;
        // 累计数量
        const total = secondary?.details
          .filter((item) => item.checked)
          .reduce((acc, cur) => {
            return acc + cur.quantity;
          }, 0);

        // 不可超过最大可选数量
        if (total < maxNum) {
          Toast.show({
            content: `${secondary.className}必须选满${secondary.maxQuantity}个`,
            position: "top",
          });
          return false;
        }
        return true;
      });
      // secondary存在才校验
      return product?.secondary ? res?.every((item) => item) : true;
    });

    return res?.every((item) => item);
  };

  const onQuantityChange = (productId, value, food, round) => {
    const temp = [...packageProductList];
    const product = temp.find((item) => item.id === productId);
    const secondary = product.secondary.find((item) => item.round === round);
    const detail = secondary.details.find((item) => item.id === food.id);

    const total = secondary.details
      .filter((item) => item.id !== food.id && item.checked)
      .reduce((acc, cur) => {
        return acc + cur.quantity;
      }, 0);

    if (total + value <= secondary.maxQuantity) {
      detail.quantity = value;
      setPackageProductList(temp);
    } else {
      Toast.show({
        content: `最多只能选${secondary.maxQuantity}个`,
        position: "top",
      });
    }
  };

  const onCustomChecked = (checked, code, mode = "options") => {
    const temp = [...packageProductList];
    const product = temp.find((item) => item.id === checkedInfo.productId);
    const secondary = product.secondary.find((item) => item.round === checkedInfo.round);
    const detail = secondary.details.find((item) => item.id === checkedInfo.foodId);

    const customizationTemp = { ...customization };

    if (mode === "options") {
      customizationTemp.options =
        customization?.options?.length > 0
          ? customization?.options.map((item) => {
              if (item.code === code) {
                item.checked = checked ? 1 : 0;
              }
              return item;
            })
          : [];
    } else {
      customizationTemp.items =
        customization?.items?.length > 0
          ? customization?.items.map((item) => {
              return {
                ...item,
                values: item?.values?.map((valueItem) => {
                  if (checked) {
                    if (valueItem.code === code) {
                      valueItem.checked = checked ? 1 : 0;
                    } else {
                      valueItem.checked = 0;
                    }
                  }

                  return valueItem;
                }),
              };
            })
          : [];
    }

    detail.customization = customizationTemp;

    setPackageProductList(temp);
  };

  const onSubmit = async () => {
    const json = {
      pickupCode,
      packageId: selectedPackage.id,
      storeCode: store.code,
      products: packageProductList.map((product) => {
        return {
          id: product.id,
          code: product.code,
          name: product.name,
          details: product?.secondary
            ?.map((secondary) => secondary?.details?.filter((detail) => detail?.checked))
            ?.flat(),
        };
      }),
      remark: remark?.trim(),
      tablewareCode,
    };
    console.log("json :>> ", json);
    const res = await fetchSubmit(json);
    console.log("res :>> ", res);
    if (res) {
      // setStep(4);
      fetchOrder();
    }
  };

  const renderStep = () => (
    <Steps current={step}>
      <Step
        title="选择地址"
        icon={
          <LocationFill
            fontSize={28}
            onClick={() => {
              setStep(0);
            }}
          />
        }
      />
      {/* <Step
        title="选择门店"
        icon={
          <HeartFill
            fontSize={28}
            onClick={() => {
              if (step > 1) {
                setStep(1);
              }
            }}
          />
        }
      /> */}
      <Step
        title="填写详情"
        icon={
          <EditFill
            fontSize={28}
            onClick={() => {
              if (step > 1) {
                setStep(1);
              }
            }}
          />
        }
      />
      {/* <Step
        title="选套餐"
        icon={
          <HeartFill
            fontSize={28}
            onClick={() => {
              if (step > 2) {
                setStep(2);
              }
            }}
          />
        }
      /> */}
      <Step
        title="选商品"
        icon={
          <HeartFill
            fontSize={28}
            onClick={() => {
              if (step > 2) {
                setStep(2);
              }
            }}
          />
        }
      />
      <Step title="确认信息" icon={<CheckCircleFill fontSize={28} />} />

      {/* <Step title="下单结果" icon={<InformationCircleFill fontSize={28} />} /> */}
    </Steps>
  );
  const renderByStep = (step: number) => {
    const renderMap: Record<number, ReactElement> = {
      0: renderSearchAddr(),
      //   1: renderSelectStore(),
      1: renderForm(),
      // 2: renderSelectPackage(),
      2: renderSelectFood(),
      3: renderConfirm(),
      // 4: renderResult(),
    };
    return renderMap[step];
  };

  const onCityClick = async () => {
    setCityPopupVisible(true);
  };

  const renderSearchAddr = () => {
    return (
      <div style={{ height: "40vh", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <SearchBar
            placeholder="城市"
            icon={null}
            onFocus={onCityClick}
            value={city}
            clearable={false}
            style={{ flex: 0.6, marginLeft: "10px", marginRight: "10px" }}
          />
          <SearchBar
            placeholder="路名/小区/写字楼/学校等"
            onChange={(val) => fetchAddress({ cityName: city, keyword: val })}
            onSearch={(val) => fetchAddress({ cityName: city, keyword: val })}
            style={{ flex: 1.5, marginRight: "10px" }}
          />
        </div>
        {isAddrLoading ? (
          <div className="flex" style={{ marginTop: "30px" }}>
            <SpinLoading />
          </div>
        ) : (
          <List>
            {addrList.length > 0 ? (
              addrList.map((item: any) => (
                <List.Item key={item.id} onClick={() => onAddrItemClick(item)}>
                  <div className="list-item">
                    <div className="list-title">{item.name}</div>
                    <div className="list-info">{item.address}</div>
                  </div>
                </List.Item>
              ))
            ) : (
              <div style={{ margin: "50px 0" }}>
                <ErrorBlock status="empty" title="请先输入地点关键字" description="" />
              </div>
            )}
          </List>
        )}
      </div>
    );
  };

  //   const renderSelectStore = () => {
  //     return (
  //       <>
  //         <CheckList
  //           defaultValue={[0]}
  //           onChange={(values) => {
  //             if (values?.length === 0) {
  //               setStore(null);
  //             } else {
  //               setStore(storeList[+values[0]]);
  //             }
  //           }}
  //           style={{ height: "40vh" }}
  //         >
  //           {storeList?.map((item: any, index: number) => (
  //             <CheckList.Item value={index}>
  //               <div className="list-item">
  //                 <div className="list-title">{item.name}</div>
  //                 <div className="list-info">{item.distanceText}</div>
  //               </div>
  //             </CheckList.Item>
  //           ))}
  //         </CheckList>
  //         <div style={{ padding: "20px 12px" }}>
  //           <Button
  //             block
  //             disabled={!store}
  //             color="primary"
  //             size="large"
  //             style={{ marginTop: "40px" }}
  //             onClick={onStoreSubmit}
  //           >
  //             确定
  //           </Button>
  //         </div>
  //       </>
  //     );
  //   };

  const renderForm = () => {
    return (
      <Form
        name="form"
        onFinish={onAddrFormSubmit}
        style={{ minHeight: "40vh" }}
        initialValues={{ address: `${addr?.address} - ${addr?.name}`, store: store?.name }}
        footer={
          <Button
            block
            type="submit"
            color="primary"
            size="large"
            style={{ marginTop: "10px" }}
            loading={isSaveAddressLoading}
          >
            <EditSFill fontSize={20} style={{ marginRight: "4px" }} />
            进入选餐
          </Button>
        }
      >
        <Form.Item name="address" label="地址">
          <TextArea rows={2} placeholder="请输入地址" disabled />
        </Form.Item>
        {/*<Form.Item name="store" label="门店">*/}
        {/*  <div className="flex">*/}
        {/*    <TextArea rows={1} value={store?.name} placeholder="请选择门店" disabled />*/}
        {/*    <Button*/}
        {/*      color="primary"*/}
        {/*      size="small"*/}
        {/*      onClick={async () => {*/}
        {/*        const value = await Picker.prompt({*/}
        {/*          columns: [storeList.map((store) => ({ label: store.name, value: store }))],*/}
        {/*        });*/}
        {/*        value && setStore(value?.[0]);*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <DownOutline />*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*</Form.Item>*/}
        <Form.Item name="name" label="联系人" rules={[{ required: true }]}>
          <Input placeholder="请输入联系人" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="电话"
          rules={[{ required: true, pattern: /^1[3-9]\d{9}$/, message: "请输入有效电话" }]}
        >
          <Input placeholder="请输入电话" maxLength={11} />
        </Form.Item>
        <Form.Item name="detailAddr" label="门牌号" rules={[{ required: true }]}>
          <Input placeholder="请输入门牌号" />
        </Form.Item>
      </Form>
    );
  };

  // const renderSelectPackage = () => {
  //   return (
  //     <div style={{ padding: "4px 12px", color: "#666" }}>
  //       <div className="header">选择套餐</div>
  //       <div>
  //         {packageData?.map((item, index) => (
  //           <Card style={{ margin: "16px 0" }} key={index}>
  //             <div className="flex" style={{ justifyContent: "space-between" }}>
  //               <div>
  //                 <img src={item.image} alt="" style={{ width: "80px", height: "80px" }} />
  //               </div>
  //               <div style={{ margin: "0 4px" }}>
  //                 <div style={{ fontSize: "14px", color: "black", fontWeight: 500, marginBottom: "8px" }}>
  //                   {item.name}
  //                 </div>
  //                 <div style={{ display: "flex", justifyContent: "center" }}>
  //                   使用时间: {item.tradeStartTime} - {item.tradeEndTime}
  //                 </div>
  //               </div>
  //               <div style={{ minWidth: "18%" }}>
  //                 <Button color="primary" size="small" onClick={() => onSelectPackage(item)}>
  //                   兑换
  //                 </Button>
  //               </div>
  //             </div>
  //           </Card>
  //         ))}
  //       </div>

  //       {/* <Button
  //         onClick={() => {
  //           setStep(3);
  //         }}
  //         block
  //         color="primary"
  //         size="large"
  //         style={{ marginTop: "10px" }}
  //       >
  //         下一步
  //       </Button> */}
  //     </div>
  //   );
  // };

  const renderSelectFood = () => {
    return (
      <div style={{ padding: "4px 12px", color: "#666" }}>
        <div className="header">选择商品和定制</div>
        <NoticeBar
          style={{ margin: "12px 0" }}
          content="如果可选类中没有商品, 是因为该门店没有"
          color="alert"
          closeable
        />
        <div className="food-select">
          {packageProductList.map((product, index) => (
            <div key={index}>
              {product.secondary ? (
                product.secondary?.map((secondary, secondaryIndex) => {
                  return (
                    <div style={{ marginBottom: "20px" }} key={secondaryIndex}>
                      <div style={{ fontSize: "15px", marginBottom: "12px", color: "#ff9900" }}>
                        {index + 1} - {secondary.round} - {secondary.className} (请选择{secondary.maxQuantity}个)
                      </div>
                      <div className="flex" style={{ flexWrap: "wrap", gap: "30px 24px" }}>
                        {secondary?.details?.map((food) => {
                          return (
                            <div style={{ width: "95px" }} key={food.id}>
                              <Checkbox
                                style={{
                                  "--icon-size": "18px",
                                  "--font-size": "14px",
                                  "--gap": "6px",
                                }}
                                checked={!!food.checked}
                                onChange={(checked) => onFoodChecked(product.id, checked, food, secondary.round)}
                              >
                                <img
                                  src={food.image}
                                  style={{ width: "75px", height: "75px", marginTop: "10px", objectFit: "cover" }}
                                />
                                <span style={{ fontSize: "12px", textAlign: "center" }}>{food.name}</span>
                              </Checkbox>
                              {food.hasCustomization === 1 && food.checked === 1 ? (
                                <div className="flex">
                                  <Button
                                    color="primary"
                                    size="mini"
                                    style={{
                                      width: "75px",
                                      marginTop: "4px",
                                      "--background-color": "#ff9900",
                                      border: 0,
                                    }}
                                    onClick={() => {
                                      setCheckedInfo({
                                        productId: product.id,
                                        round: secondary.round,
                                        foodId: food.id,
                                      });
                                      if (!food.customization) {
                                        fetchCustomization({ storeCode: store.code, productCode: food.code });
                                      } else {
                                        setCustomization(food.customization);
                                      }
                                      setSecondaryPopupVisible(true);
                                    }}
                                  >
                                    定制
                                  </Button>
                                </div>
                              ) : null}
                              {food.checked === 1 && secondary.maxQuantity > 1 ? (
                                <Stepper
                                  style={{ marginTop: "6px" }}
                                  min={0}
                                  max={food.maxQuantity}
                                  value={food.quantity}
                                  onChange={(value) => onQuantityChange(product.id, value, food, secondary.round)}
                                ></Stepper>
                              ) : null}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ marginBottom: "20px" }}>
                  <div style={{ fontSize: "15px", marginBottom: "12px", color: "#ff9900" }}>
                    {index + 1} - {product.name}
                  </div>
                  <div className="flex" style={{ flexWrap: "wrap", gap: "30px 24px" }}>
                    <div style={{ width: "95px" }}>
                      <Checkbox
                        style={{
                          "--icon-size": "18px",
                          "--font-size": "14px",
                          "--gap": "6px",
                        }}
                        checked={true}
                      >
                        <img
                          src={product.image}
                          style={{ width: "75px", height: "75px", marginTop: "10px", objectFit: "cover" }}
                        />
                        <span style={{ fontSize: "12px", textAlign: "center" }}>{product.name}</span>
                      </Checkbox>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          <Popup
            visible={secondaryPopupVisible}
            showCloseButton
            onClose={() => {
              setSecondaryPopupVisible(false);
            }}
            className="secondary-popup"
            closeOnMaskClick
          >
            <div className="flex" style={{ padding: "30px 20px", flexWrap: "wrap" }}>
              {customization?.options &&
                customization?.options?.map((item) => (
                  <Checkbox
                    style={{
                      "--icon-size": "18px",
                      "--font-size": "14px",
                      "--gap": "6px",
                      margin: "10px 6px",
                    }}
                    onChange={(checked) => onCustomChecked(checked, item.code, "options")}
                    disabled={item.soldOut}
                    checked={item.checked}
                  >
                    <img
                      src={item.image}
                      style={{ width: "75px", height: "75px", marginTop: "10px", objectFit: "cover" }}
                    />
                    <span style={{ fontSize: "12px", textAlign: "center" }}>{item.name}</span>
                  </Checkbox>
                ))}
              {customization?.items &&
                !isCustomizationLoading &&
                customization?.items?.map((item) => (
                  <>
                    <div>{item?.name} : </div>
                    {item?.values?.map((valueItem) => (
                      <Checkbox
                        style={{
                          "--icon-size": "18px",
                          "--font-size": "14px",
                          "--gap": "6px",
                          margin: "10px 6px",
                        }}
                        onChange={(checked) => onCustomChecked(checked, valueItem.code, "items")}
                        checked={valueItem.checked}
                      >
                        <img
                          src={valueItem.image}
                          style={{ width: "75px", height: "75px", marginTop: "10px", objectFit: "cover" }}
                        />
                        <span style={{ fontSize: "12px", textAlign: "center" }}>{valueItem.name}</span>
                      </Checkbox>
                    ))}
                  </>
                ))}
            </div>
          </Popup>
        </div>
        <Button
          onClick={() => {
            if (validateQuantity()) {
              setStep(3);
            }
          }}
          block
          color="primary"
          size="large"
          style={{ marginTop: "40px" }}
        >
          下一步
        </Button>
      </div>
    );
  };

  const renderConfirm = () => {
    return (
      <div style={{ padding: "4px 12px", color: "#666" }}>
        <div className="header">确定商品和收货信息</div>
        <NoticeBar
          style={{ margin: "12px 0" }}
          content="请务必核实好收货信息, 地址或电话填错代下成功不退"
          color="alert"
          closeable
        />
        <Card className="card" title="商品信息">
          <div className="formItem">
            <div className="label">商品</div>
            <div>
              {packageProductList.map((product, index) => (
                <div
                  key={index}
                  className="value flex"
                  style={{ justifyContent: "left", marginLeft: "80px", marginBottom: "12px", marginTop: "12px" }}
                >
                  <img src={product.image} style={{ width: "60px", height: "60px" }} />
                  <div style={{ marginLeft: "18px", textAlign: "right", flex: 1 }}>
                    <div style={{ fontWeight: 500, marginBottom: "6px" }}>{product.name}</div>
                    <div style={{ fontWeight: 500, marginTop: "6px" }}>
                      <span>{product.quantity || 1}份</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>
        {/*<Card className="card" title="门店信息">*/}
        {/*  <div className="formItem">*/}
        {/*    <div className="label">门店</div>*/}
        {/*    <div className="value">{store?.name}</div>*/}
        {/*  </div>*/}
        {/*</Card>*/}

        <Card className="card" title="收货信息">
          <div className="formItem">
            <div className="label">配送地址</div>
            <div className="value">
              {userAddrInfo.cityName}-{userAddrInfo.streetAddress}-{userAddrInfo.detail}
            </div>
          </div>
          <div className="formItem">
            <div className="label">联系人</div>
            <div className="value">{userAddrInfo.fullName}</div>
          </div>
          <div className="formItem">
            <div className="label">联系人电话</div>
            <div className="value">{userAddrInfo.phone}</div>
          </div>
        </Card>
        <Card className="card" title="订单信息">
          <div className="formItem" style={{ display: "flex" }}>
            <div className="label">备注</div>
            <TextArea
              placeholder="请输入备注"
              autoSize
              value={remark}
              onChange={(val) => {
                setRemark(val);
              }}
              style={{
                width: "60%",
                "--font-size": "12px",
                "--text-align": "right",
                flex: 1,
              }}
            />
          </div>
          <div className="formItem flex">
            <div className="label">餐具</div>
            <div className="value">
              <Switch
                uncheckedText="不需要"
                checkedText="需要"
                style={{ "--width": "20px", fontSize: "12px" }}
                onChange={(checked) => {
                  setTablewareCode(checked ? "yes" : "no");
                }}
              />
            </div>
          </div>
        </Card>
        <Button
          onClick={() => {
            onSubmit();
          }}
          block
          color="primary"
          size="large"
          style={{ marginTop: "20px" }}
          loading={isSubmitLoading}
        >
          确定下单
        </Button>
      </div>
    );
  };

  // 下单中
  const renderSubmiting = () => {
    return (
      <div className="result" style={{ height: "100vh" }}>
        <Result
          status="waiting"
          style={{ borderRadius: "8px" }}
          title={
            <div>
              <DotLoading />
              正在下单中
              <DotLoading />
            </div>
          }
          description="请稍等片刻"
        />
        <Card style={{ marginTop: "20px" }}>
          <ul>
            <li>预计1～5分钟刷新，高峰期可能出现延迟</li>
            <li>如遇下单失败请联系客服处理</li>
            <li>本商品为特价现制商品不支持退换，如遇食品有质量问题请联系门店服务员处理</li>
            <li>本商品仅为第三方代点餐服务，即第三方代下单服务，与麦当劳官方无关</li>
          </ul>
        </Card>
      </div>
    );
  };

  const resultTitleMap = {
    1: {
      title: "配餐中",
      desc: "正在为您制作餐品, 请稍等",
    },
    2: {
      title: "配送中",
      desc: "美味佳肴片刻就到",
    },
    3: {
      title: "已完成",
      desc: "订单已准备完毕，喜欢您再来",
    },
  };

  const renderResult = () => {
    return (
      <div className="result">
        <div style={{ fontSize: "32px", fontWeight: "bold" }}>
          <span>{orderInfo?.orderStatus}</span>
          <span className="delivery-tag">麦乐送</span>
        </div>

        <div className="flex" style={{ marginBottom: "25px", marginTop: "3px", justifyContent: "flex-start" }}>
          <img
            data-v-70b96c4d=""
            src="https://img.mcd.cn/gallery/7b45cd732ac9fb1e.png"
            style={{ height: "14px", width: "14px", marginRight: "2px" }}
          />
          <span>{orderInfo?.orderStatusSubTitle}</span>
        </div>
        {/* 配送中 */}
        {orderInfo?.orderStatusCode == "4" && (
          <Card className="card" title="骑手信息">
            <div className="formItem">
              <div className="label">骑手姓名</div>
              <div className="value">{orderInfo?.deliveryInfo?.riderNickName}</div>
            </div>
            <div className="formItem">
              <div className="label">骑手电话</div>
              <div className="value">{orderInfo?.deliveryInfo?.riderMobilePhone}</div>
            </div>
            <div className="formItem">
              <div className="label">骑手距离</div>
              <div className="value">{orderInfo?.deliveryInfo?.riderLocation?.distance} km</div>
            </div>
            <div className="formItem">
              <div className="label">预计送达时间</div>
              <div className="value">{orderInfo?.deliveryInfo?.expectDeliveryTime}</div>
            </div>
            <div className="formItem">
              <div className="label">配送方式</div>
              <div className="value">{orderInfo?.deliveryInfo?.deliveryTypeLabel}</div>
            </div>
          </Card>
        )}
        <Card className="card" title="配送信息">
          <div className="formItem">
            <div className="label">配送地址</div>
            <div className="value">
              {orderInfo?.deliveryInfo?.deliveryAddress} - {orderInfo?.deliveryInfo?.addressDetail}
            </div>
          </div>
          <div className="formItem">
            <div className="label">联系人</div>
            <div className="value">{orderInfo?.deliveryInfo?.customerNickname}</div>
          </div>
          <div className="formItem">
            <div className="label">联系人电话</div>
            <div className="value">{orderInfo?.deliveryInfo?.mobilePhone}</div>
          </div>
        </Card>
        <Card className="card" title="商品信息">
          <div className="formItem">
            <div className="label">门店</div>
            <div className="value">{orderInfo?.storeName}</div>
          </div>
          <div className="formItem">
            <div className="label">门店地址</div>
            <div className="value">{orderInfo?.storeAddress}</div>
          </div>
          <div className="formItem" style={{ position: "relative" }}>
            <div className="label">商品</div>
            <div>
              {orderInfo?.orderProductList?.map((product) => (
                <div
                  className="value flex"
                  style={{ justifyContent: "left", marginLeft: "30px", marginBottom: "12px", marginTop: "12px" }}
                >
                  <img src={product.productImage} style={{ width: "60px", height: "60px" }} />
                  <div style={{ marginLeft: "18px", textAlign: "left", flex: 1 }}>
                    <div style={{ fontWeight: 500, marginBottom: "6px" }}>{product.productName}</div>
                    {product.comboItemList?.map((item) => (
                      <div>
                        <span>{item.quantity} x </span>
                        <span>{item.name}</span>
                      </div>
                    ))}

                    <div style={{ fontWeight: 500, marginTop: "6px" }}>
                      <span>{product.quantity}份</span>
                      <span style={{ position: "absolute", right: "0" }}>¥ {product.price}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="formItem">
            <div className="label">商品小计</div>
            <div className="value" style={{ fontWeight: 500 }}>
              ¥ {orderInfo?.productTotalPrice}
            </div>
          </div>
          <div className="formItem">
            <div className="label">运费</div>
            <div className="value" style={{ fontWeight: 500 }}>
              ¥ {orderInfo?.deliveryPrice}
            </div>
          </div>
          {/*<div className="formItem">
            <div className="label">实付</div>
            <div className="value" style={{ fontWeight: 500 }}>
              ¥ {orderInfo?.realTotalAmount}
            </div>
          </div>*/}
        </Card>
        <Card className="card" title="订单信息">
          <div className="formItem">
            <div className="label">订单号</div>
            <div className="value">{orderInfo?.orderId}</div>
          </div>
          <div className="formItem">
            <div className="label">就餐方式</div>
            <div className="value">{orderInfo?.orderTypeName}</div>
          </div>
          {/* <div className="formItem">
            <div className="label">取餐码</div>
            <div className="value">xxxx</div>
          </div> */}
          <div className="formItem">
            <div className="label">餐具需求</div>
            <div className="value">{orderInfo?.tablewareInfo}</div>
          </div>
          <div className="formItem">
            <div className="label">备注</div>
            <div className="value">{orderInfo?.remark || "无"}</div>
          </div>
          <div className="formItem">
            <div className="label">支付方式</div>
            <div className="value">{orderInfo?.paymentChannelLabel}</div>
          </div>
          <div className="formItem">
            <div className="label">下单时间</div>
            <div className="value">{orderInfo?.createTime}</div>
          </div>
          <div className="formItem">
            <div className="label">支付时间</div>
            <div className="value">{orderInfo?.saleTime}</div>
          </div>
        </Card>
      </div>
    );
  };

  if (emptyContent) {
    return null;
  }

  if (isOrderFistLoading) {
    return (
      <>
        <Skeleton.Title animated />
        <Skeleton.Paragraph lineCount={5} animated />
      </>
    );
  }

  return (
    <div className="App">
      {isPaying && renderSubmiting()}
      {!isPaying && hasOrder && renderResult()}
      {!isPaying && !hasOrder && (
        <>
          <div className="steps">{renderStep()}</div>
          <div className="main">{renderByStep(step)}</div>
          {/* <div className="flex imgDiv">
            <img className="img" src={Logo} alt="logo" />
          </div> */}

          <Popup
            visible={cityPopupVisible}
            onMaskClick={() => {
              setCityPopupVisible(false);
            }}
            onClose={() => {
              setCityPopupVisible(false);
            }}
            position="top"
            bodyStyle={{ height: "80vh" }}
          >
            <IndexBar>
              {cityList?.map(({ cities, initial }) => {
                return (
                  <IndexBar.Panel index={initial} title={initial} key={initial}>
                    <List>
                      {(cities as any).map((item, index) => (
                        <List.Item key={index} onClick={() => onCityListItemClick(item)} arrow={false}>
                          {item.name}
                        </List.Item>
                      ))}
                    </List>
                  </IndexBar.Panel>
                );
              })}
            </IndexBar>
          </Popup>
        </>
      )}
    </div>
  );
}

export default App;
