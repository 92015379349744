import axios from "axios";
import { useState } from "react";
import { debounce } from "lodash";
import { Toast } from "antd-mobile";

const HOST_MAP = { dev: "http://localhost:1992", prod: "https://www.zyyi.life/prod-api" };
const HOST = HOST_MAP.prod;

export const useFetchAddress = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const fetchAddress = async (params) => {
    if (!params?.cityName) {
      return;
    }
    setLoading(true);
    try {
      const res = await axios.get(HOST + "/mcd/external/search", { params });
      if (res?.data?.code === 200) {
        const list = res?.data?.data || [];
        setData(list);
      }
    } catch (e) {
      console.log("e :>> ", e);
    }
    setLoading(false);
  };
  return { data, fetchAddress: debounce(fetchAddress, 500), loading };
};

export const useFetchStores = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const fetchStores = async ({ latitude, longitude }: any) => {
    setLoading(true);
    try {
      const pickupCode = new URLSearchParams(window.location.search).get("pickupCode");
      const res = await axios.get(HOST + "/mcd/external/near/stores", {
        params: { latitude, longitude, pickupCode },
      });
      const data = res?.data?.data || {};
      // if (!data?.stores?.length || data?.stores?.length < 1) {
      //   Toast.show({
      //     icon: "fail",
      //     //   content: res?.data?.msg || "抱歉,未找到匹配的外送门店",
      //     content: "抱歉,未找到匹配的外送门店",
      //   });
      //   return [];
      // } else {
      //   setData(data.stores || []);
      //   return data.stores;
      // }
      return [];
    } catch (e) {
      console.log("e :>> ", e);
    }
    setLoading(false);
  };
  return { data, fetchStores, loading };
};

export const useFetchCityByLocation = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchCityByLocation = async ({ latitude, longitude }: any) => {
    setLoading(true);
    try {
      const res = await axios.get(HOST + "/mcd/external/city", {
        params: { latitude, longitude },
      });

      if (res?.data?.code === 200) {
        const city = res?.data?.data?.city?.name;
        setData(city);
      } else {
        Toast.show({
          icon: "fail",
          content: "定位错误, 请手动选择城市",
        });
      }
    } catch (e) {
      console.log("e :>> ", e);
    }
    setLoading(false);
  };
  return { data, fetchCityByLocation, loading, setData };
};

export const useFetchCityList = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchCityList = async () => {
    setLoading(true);
    try {
      const res = await axios.get(HOST + "/mcd/external/cities/groups");

      if (res?.data?.code === 200) {
        const list = res?.data?.data;
        setData(list);
      } else {
        Toast.show({
          icon: "fail",
          content: res?.data?.msg || "获取城市列表失败",
        });
      }
    } catch (e) {
      console.log("e :>> ", e);
    }
    setLoading(false);
  };
  return { data, fetchCityList, loading };
};

export const useFetchPackage = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchPackage = async (pickupCode = "xLVaxXdb") => {
    setLoading(true);
    try {
      const res = await axios.get(HOST + "/mcd/external/package", { params: { pickupCode } });
      if (res?.data?.code === 200) {
        setData(res?.data?.data);
        return res?.data?.data;
      } else {
        Toast.show({
          icon: "fail",
          content: res?.data?.msg || "获取套餐失败",
        });
        return false;
      }
    } catch (e) {
      console.log("e :>> ", e);
    }
    setLoading(false);
  };
  return { data, fetchPackage, loading };
};

// 获取商品定制详情
export const useFetchCustomization = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchCustomization = async (params) => {
    setLoading(true);
    try {
      const res = await axios.get(HOST + "/mcd/product/customization/info", { params: { ...params, orderType: 2 } });
      if (res?.data?.code === 200) {
        setData(res?.data?.data);
      } else {
        Toast.show({
          icon: "fail",
          content: res?.data?.msg || "获取定制详情失败",
        });
      }
    } catch (e) {
      console.log("e :>> ", e);
    }
    setLoading(false);
  };
  return { data, fetchCustomization, loading, setData };
};

// 获取订单详情
export const useFetchOrderInfo = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchOrderInfo = async (pickupCode = "xLVaxXdb") => {
    setLoading(true);
    try {
      const res = await axios.get(HOST + "/mcd/external/order/info", { params: { pickupCode } });
      if (res?.data?.code === 200) {
        setData(res?.data?.data);
        return res?.data?.data;
      } else {
        // Toast.show({
        //   icon: "fail",
        //   content: "获取订单失败",
        // });
        return false;
      }
    } catch (e) {
      console.log("e :>> ", e);
    } finally {
      setLoading(false);
    }
  };
  return { data, fetchOrderInfo, loading };
};

// 保存地址
export const useFetchSaveAddress = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchSaveAddress = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(HOST + "/mcd/external/address", data);
      if (res?.data?.code === 200) {
        setData(res?.data?.data);
        return true;
      } else {
        Toast.show({
          icon: "fail",
          content: res?.data?.msg || "保存地址失败",
        });
      }
    } catch (e) {
      console.log("e :>> ", e);
    } finally {
      setLoading(false);
    }
  };
  return { data, fetchSaveAddress, loading };
};

// 提交下单
export const useFetchSubmit = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await axios.post(HOST + "/mcd/external/order", data);
      if (res?.data?.code === 200) {
        setData(res?.data?.data);
        return res?.data?.data;
      } else {
        Toast.show({
          icon: "fail",
          content: res?.data?.msg || "下单失败",
        });
      }
    } catch (e) {
      console.log("e :>> ", e);
    } finally {
      setLoading(false);
    }
  };
  return { data, fetchSubmit, loading };
};

// 校验门店套餐是否可用
export const useFetchCheckPackage = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const fetchCheckPackage = async (params) => {
    setLoading(true);
    try {
      const res = await axios.get(HOST + "/mcd/external/check/package", { params });
      if (res?.data?.code === 200) {
        setData(true);
        return true;
      } else {
        Toast.show({
          icon: "fail",
          content: res?.data?.msg || "该门店套餐不可用",
        });
      }
    } catch (e) {
      console.log("e :>> ", e);
    } finally {
      setLoading(false);
    }
  };
  return { data, fetchCheckPackage, loading };
};
